import dayjs from 'dayjs';

export const columns = [
  {
    align: 'center',
    title: '编码',
    dataIndex: 'code',
    customRender(text) {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '广告主',
    dataIndex: 'advertiserName',
    customRender(text) {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '统计周期',
    customRender: (text, row) => {
      return `${dayjs(row.startTime).format('YYYY/MM/DD') || '-'} ~ ${dayjs(row.endTime).format('YYYY/MM/DD') || '-'}`;
    },
  },
  {
    align: 'center',
    title: '消耗目标',
    dataIndex: 'costTarget',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '线索量目标',
    dataIndex: 'clueTarget',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '线索单价目标',
    dataIndex: 'clueUnitPriceTarget',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '私信开口人数目标',
    dataIndex: 'msgOpenNumTarget',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '私信开口单价目标',
    dataIndex: 'msgUnitPriceTarget',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '私信线索转化率目标（%）',
    dataIndex: 'msgClueConvertTarget',
    customRender(text) {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: 'UBL消耗占比目标（%）',
    dataIndex: 'ublCostProportionTarget',
    customRender(text) {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
  {
    align: 'center',
    title: '最后操作人',
    dataIndex: 'modifier',
    customRender(text) {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '最后操作时间',
    dataIndex: 'mtime',
    customRender(text) {
      return text || '-';
    },
  },
];

const getCellStyle = (state) => ({
  backgroundColor: state === 1 ? '#ff4d4f' : '#ffffff',
  color: state === 1 ? '#ffffff' : 'inherit',
});

export const targetBoardColumns = [
  {
    align: 'center',
    title: '编码',
    dataIndex: 'code',
    customRender(text) {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '广告主',
    dataIndex: 'advertiserName',
    customRender(text) {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '统计周期',
    customRender: (text, row) => {
      return `${dayjs(row.startTime).format('YYYY/MM/DD') || '-'} ~ ${dayjs(row.endTime).format('YYYY/MM/DD') || '-'}`;
    },
  },
  {
    align: 'center',
    title: '消耗累计/目标',
    customRender: (record) => {
      return record.costTarget ? `${record.costCount || 0}/${record.costTarget || 0}` : '';
    },
    customCell: (record) => {
      return {
        style: getCellStyle(record.costState),
      };
    },
  },
  {
    align: 'center',
    title: '线索量累计/目标',
    customRender: (record) => {
      return record.clueTarget ? `${record.clueCount || 0}/${record.clueTarget || 0}` : '';
    },
    customCell: (record) => {
      return {
        style: getCellStyle(record.clueState),
      };
    },
  },
  {
    align: 'center',
    title: '线索单价累计/目标',
    customRender: (record) => {
      return record.clueUnitPriceTarget ? `${record.clueUnitPriceCount || 0}/${record.clueUnitPriceTarget || 0}` : '';
    },
    customCell: (record) => {
      return {
        style: getCellStyle(record.clueUnitPriceCountState),
      };
    },
  },
  {
    align: 'center',
    title: '私信开口人数累计/目标',
    customRender: (record) => {
      return record.msgOpenNumTarget ? `${record.msgOpenNumCount || 0}/${record.msgOpenNumTarget || 0}` : '';
    },
    customCell: (record) => {
      return {
        style: getCellStyle(record.msgOpenNumCountState),
      };
    },
  },
  {
    align: 'center',
    title: '私信开口单价累计/目标',
    customRender: (record) => {
      return record.msgUnitPriceTarget ? `${record.msgUnitPriceCount || 0}/${record.msgUnitPriceTarget || 0}` : '';
    },
    customCell: (record) => {
      return {
        style: getCellStyle(record.msgUnitPriceCountState),
      };
    },
  },
  {
    align: 'center',
    title: '私信线索转化率累计/目标（%）',
    customRender: (record) => {
      return record.msgClueConvertTarget
        ? `${record.msgClueConvertCount || 0}/${record.msgClueConvertTarget || 0}`
        : '';
    },
    customCell: (record) => {
      return {
        style: getCellStyle(record.msgClueConvertCountState),
      };
    },
  },
  {
    align: 'center',
    title: 'UBL消耗占比累计/目标（%）',
    customRender: (record) => {
      return record.ublCostProportionTarget
        ? `${record.ublCostProportionCount || 0}/${record.ublCostProportionTarget || 0}`
        : '';
    },
    customCell: (record) => {
      return {
        style: getCellStyle(record.ublCostProportionCountState),
      };
    },
  },
  {
    align: 'center',
    title: '投手',
    dataIndex: 'operatorName',
    customRender(text) {
      return text || '-';
    },
  },
];
