<template>
  <a-card>
    <a-page-header :title="'目标管理'" />
    <a-form-model ref="form" :model="form" v-bind="layout">
      <a-row type="flex">
        <a-col :span="7">
          <a-form-model-item label="广告主">
            <a-input v-model="form.advertiserName" placeholder="请输入" :allow-clear="true" />
          </a-form-model-item>
        </a-col>
        <a-col class="button-wrapper">
          <a-button type="primary" @click="handleSearch">查询</a-button>
        </a-col>
      </a-row>
    </a-form-model>
    <a-button type="primary" @click="newTarget" style="margin-bottom: 20px">新建目标</a-button>
    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="dataList"
      :row-key="(record, index) => record.id"
      :pagination="false"
    >
      <div slot="action" slot-scope="text, record">
        <base-button :type="'link'" :title="'修改'" @onClickBtn="editTarget(record.id)"></base-button>
        <base-button :type="'link'" :title="'删除'" @onClickBtn="deleteTarget(record.id)"></base-button>
      </div>
    </a-table>
    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </a-card>
</template>

<script>
import { columns } from './data/constants';

export default {
  data() {
    return {
      columns,
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      form: {
        advertiserName: undefined,
      },
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 100,
        total: 0,
      },
      loading: false,
    };
  },
  methods: {
    handleSearch() {
      this.pagination.current = 1;
      this.getDataList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    newTarget() {
      this.$router.push({
        name: 'targetEdition',
        query: {},
      });
    },
    editTarget(id) {
      this.$router.push({
        name: 'targetEdition',
        query: { id },
      });
    },
    getDataList() {
      this.loading = true;
      const _form = this.form;
      let params = Object.assign(_form, {
        page: this.pagination.current,
        size: this.pagination.pageSize,
      });
      this.$api.core.deliverManage
        .getAdTargetList(params)
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.dataList = res.data.list || [];
            this.pagination.total = res.data.total || 0;
          } else {
            this.dataList = [];
            this.pagination.total = 0;
            console.error(`获取列表失败${res}`);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteTarget(id) {
      this.$confirm({
        title: '确定删除？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.$api.core.deliverManage.deleteAdTarget(id).then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功');
              this.getDataList();
            } else {
              this.$message.error('删除失败');
            }
          });
        },
      });
    },
  },
  created() {
    this.getDataList();
  },
};
</script>

<style scoped lang="scss">
::v-deep .ant-page-header {
  padding-left: 0;
  padding-right: 0;
}
.button-wrapper {
  margin-top: 4px;
  margin-left: 10px;
}
</style>
